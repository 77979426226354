<script setup lang="ts">
interface Props {
  label: string;
  color?: string;
  textColor?: string;
  size?: 'md' | 'lg';
}

const props = withDefaults(defineProps<Props>(), {
  color: 'primary',
  textColor: 'white',
  size: 'md',
});

// const createdAtRangeString = computed(() =>
//   props.range.value
//     ? `${props.range.value?.from} - ${props.range.value?.to}`
//     : ''
// );

// const update = () => {
//   console.log('update');
// };

const btnClasses = [
  'q-btn',
  'q-btn-item',
  'non-selectable',
  'no-outline',
  'q-btn--unelevated',
  'q-btn--rectangle',
  `bg-${props.color}`,
  `text-${props.textColor}`,
  'q-btn--actionable',
  'q-focusable',
  'q-hoverable ',
  'q-btn--no-uppercase',
  'rounded-4',
  'q-btn--active',
].join(' ');
</script>

<template lang="pug">
button(
  tabindex="0"
  :class="btnClasses"
  style="font-size: 16px; padding: 16px 28px;")
  span.q-focus-helper
  span(class="q-btn__content text-center col items-center q-anchor--skip justify-center row")
    span.block.btn__label {{ label }}
</template>

<style lang="sass">

// < Procects center
.btn__label
  font-family: Inter
  font-weight: 500
  line-height: 19.36px
  text-align: left

// >
</style>
