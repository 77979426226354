import { Vector2 } from 'three';
import { type TresContext, useRenderLoop } from '@tresjs/core';
import { useControls } from '@tresjs/leches';

export const useTreCameraFollow = (
  context: TresContext,
  shiftY = 0,
  touchX = false,
) => {
  const { onLoop } = useRenderLoop();

  const { CameraFollowIsActive } = useControls('CameraFollow', {
    isActive: {
      value: true,
      type: 'boolean',
    },
    speed: {
      value: 1,
      min: 0.1,
      max: 2,
      step: 0.1,
    },
  });

  const { width, height } = useWindowSize();

  const windowHalfX = computed(() => width.value / 2);
  const windowHalfY = computed(() => height.value / 2);

  // const mouse = new Vector2();
  const mouse = shiftY ? new Vector2(0, height.value * shiftY) : new Vector2();
  const target = new Vector2();

  document.addEventListener(
    'mousemove',
    (event: MouseEvent) => {
      if (!CameraFollowIsActive.value.value) return;

      mouse.x = event.clientX - windowHalfX.value;
      // mouse.y = event.clientY - windowHalfY.value;
      mouse.y = event.clientY - windowHalfY.value * (shiftY ? shiftY * 0.5 : 1);
    },
    false,
  );

  // TODO: Обработка touch-событий
  // document.addEventListener('touchmove', (e: TouchEvent) => {
  //   const touch = e.touches[0];
  //   if (!touch) return;

  //   // targetMousePos.set(
  //   //   touch.clientX / window.innerWidth,
  //   //   1 - touch.clientY / window.innerHeight,
  //   // );

  //   mouse.x = -(touch.clientX - windowHalfX.value) * 6;
  // });

  onLoop(() => {
    if (context?.camera.value) {
      const camera = context.camera.value;

      //
      target.x = (1 - mouse.x) * 0.0002;
      target.y = (1 - mouse.y) * 0.0002;

      // target.x = (1 - mouse.x) * 0.0002 + Math.sin(elapsed) * 0.01;
      // target.y = (1 - mouse.y) * 0.0002 + Math.sin(elapsed) * 0.1;

      // target.x += Math.sign(elapsed);

      camera.rotation.x += 0.05 * (target.y - camera.rotation.x);
      camera.rotation.y += 0.05 * (target.x - camera.rotation.y);

      // console.log('camera.rotation: ', camera.rotation.x);

      // camera.position.x += 0.05 * (target.y - camera.position.x);
      // camera.position.y += 0.05 * (target.x - camera.position.y);
    }
  });
};
